<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="专职队名称">
          <el-input v-model="searchForm.name" autocomplete="off" size="small" placeholder="请输入" clearable style="width:130px"></el-input>
        </el-form-item>
        <el-form-item label="行政区域">
          <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:150px"></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增消防专职队</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table :data="brigadeList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="专职队名称" :label-width="formLabelWidth">
                    <span>{{props.row.name}}</span>
                  </el-form-item>
                  <el-form-item label="消防站站长" :label-width="formLabelWidth">
                    <span>{{props.row.stationHead}}</span>
                  </el-form-item>
                  <el-form-item label="性别" :label-width="formLabelWidth">
                    <el-tag type="success" size="small" v-show="props.row.stationHeadSex===0">男</el-tag>
                    <el-tag type="danger" size="small" v-show="props.row.stationHeadSex===1">女</el-tag>
                  </el-form-item>
                  <el-form-item label="站长电话" :label-width="formLabelWidth">
                    <span>{{props.row.stationHeadPhone}}</span>
                  </el-form-item>
                  <el-form-item label="站内职务" :label-width="formLabelWidth">
                    <span>{{props.row.stationHeadJob}}</span>
                  </el-form-item>
                  <el-form-item label="图片" :label-width="formLabelWidth">
                    <el-image :src="props.row.imageUrl" fit="fill" :lazy="true" :preview-src-list="[props.row.imageUrl]"></el-image>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="所属区域" :label-width="formLabelWidth">
                    <span>{{props.row.areaName}}</span>
                  </el-form-item>
                  <el-form-item label="地址" :label-width="formLabelWidth">
                    <span>{{props.row.address}}</span>
                  </el-form-item>
                  <el-form-item label="能否出警" :label-width="formLabelWidth">
                    <template slot-scope="props">
                      <el-tag type="success" size="normal" v-show="props.row.flag===0">能</el-tag>
                      <el-tag type="danger" size="normal" v-show="props.row.flag===1">不能</el-tag>
                    </template>
                  </el-form-item>
                  <el-form-item label="站房面积" :label-width="formLabelWidth">
                    <span>{{props.row.area}}</span>
                  </el-form-item>
                  <el-form-item label="建设管理单位" :label-width="formLabelWidth">
                    <span>{{props.row.manageUnit}}</span>
                  </el-form-item>
                  <el-form-item label="经纬度" :label-width="formLabelWidth">
                    <span>{{props.row.lng}} # {{props.row.lat}}</span>
                    <el-button type="text" size="default" @click="open('location',{lng:props.row.lng,lat:props.row.lat})" icon="el-icon-location"></el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="name" label="名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="stationHead" label="消防站站长" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="stationHeadPhone" label="站长电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="typeName" label="消防站类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="flag" label="能否出警" show-overflow-tooltip>
          <template slot-scope="props">
            <el-tag type="success" size="normal" v-show="props.row.flag===0">能</el-tag>
            <el-tag type="danger" size="normal" v-show="props.row.flag===1">不能</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="经纬度">
          <template slot-scope="scope">
            <span>{{scope.row.lng.toFixed(3)}}#{{scope.row.lat.toFixed(3)}}</span>
            <el-button type="text" icon="el-icon-location" size="default" @click="open('location',{lng:scope.row.lng,lat:scope.row.lat})"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteBrigade(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="人员" placement="top">
              <el-button type="primary" @click="open('member',scope.row)" size="mini" icon="el-icon-user-solid" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 消防专职队表单弹窗 -->
    <el-dialog :title="optFlag?'添加消防站':'修改消防站'" :visible.sync="brigadeShow" width="55%" @close="clear" top="3%" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="brigade" ref="brigade" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="消防站站长" prop="stationHead" :label-width="formLabelWidth2">
              <el-input v-model="brigade.stationHead" autocomplete="off" placeholder="请输入消防站站长"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="性别" prop="stationHeadSex" :label-width="formLabelWidth">
              <el-select v-model="brigade.stationHeadSex" placeholder="请选择性别" clearable filterable>
                <el-option v-for="item in [{label:'男',value:0},{label:'女',value:1}]" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="站长电话" prop="stationHeadPhone" :label-width="formLabelWidth2">
              <el-input v-model="brigade.stationHeadPhone" autocomplete="off" placeholder="请输入站长电话" maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="站内职务" prop="stationHeadJob" :label-width="formLabelWidth">
              <el-input v-model="brigade.stationHeadJob" autocomplete="off" placeholder="请输入站内职务"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="专职队名称" :label-width="formLabelWidth2">
              <el-input v-model="brigade.name" autocomplete="off" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="消防站类型" prop="type" :label-width="formLabelWidth">
              <el-select v-model="brigade.type" placeholder="请选择消防站类型" clearable filterable>
                <el-option v-for="item in typeList" :key="item.id" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="经纬度" :label-width="formLabelWidth2">
              <el-row :gutter="20">
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lng">
                    <el-input clearable v-model.number="brigade.lng" autocomplete="off" placeholder="经度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1" :offset="0">
                  #
                </el-col>
                <el-col :span="9" :offset="0">
                  <el-form-item prop="lat">
                    <el-input clearable v-model.number="brigade.lat" autocomplete="off" placeholder="纬度" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3" :offset="0" style="padding-left:0">
                  <el-form-item>
                    <el-tooltip class="item" effect="dark" content="定位" placement="top">
                      <el-button type="text" style="font-size:16px" size="default" @click="open('map')" icon="el-icon-location"></el-button>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="能否出警" prop="flag" :label-width="formLabelWidth">
              <el-select v-model="brigade.flag" placeholder="请选择能否出警" clearable filterable>
                <el-option v-for="item in [{label:'能',value:0},{label:'不能',value:1}]" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="地址" prop="address" :label-width="formLabelWidth">
              <el-input v-model="brigade.address" autocomplete="off" placeholder="请输入地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="行政区域" :label-width="formLabelWidth2">
              <el-cascader :disabled="isLoad" v-model="brigade.areaCodes" placeholder="请选择该单位的行政区域" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:100%"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="站房面积" prop="area" :label-width="formLabelWidth2">
              <el-input v-model="brigade.area" autocomplete="off" placeholder="请输入站房面积"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="建设管理单位" prop="manageUnit" :label-width="formLabelWidth">
              <el-input v-model="brigade.manageUnit" autocomplete="off" placeholder="请输入建设管理单位"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20" :offset="0">
            <el-form-item label="图片" prop="unitImage" :label-width="formLabelWidth2">
              <el-upload ref="upload" :file-list="fileList" :headers="{Authorization:token}" action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :on-success="getImage" :on-error="error" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog title="单位图片" :visible.sync="dialogVisible" :modal-append-to-body="false">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="brigadeShow = false">取 消</el-button>
        <el-button type="primary" @click="addBrigade" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateBrigade" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 地图 -->
    <el-dialog class="dialog" :visible.sync="mapShow" width="50%">
      <div class="map">
        <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:500px" :zoom="zoom" ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
          <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          </bm-marker>
        </baidu-map>
      </div>
      <div slot="footer" class="dialog-footer" v-show="optFlag">
        <el-button @click="mapShow = false">取 消</el-button>
        <el-button type="primary" @click="getPosition">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 专职队人员列表 -->
    <el-dialog title="专职队人员" :visible.sync="memberShow" width="60%" top="3%" @close="clear">
      <div class="container noangle" v-loading="loading1">
        <div class="header">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input v-model="searchMember.name" autocomplete="off" size="small" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="类型">
              <el-select v-model="searchDeviceForm.typeId" placeholder="请选择设备类型" size="small" clearable>
                <el-option v-for="(item,i) in [{id:0,label:'兼职'},{id:1,label:'专职'},{id:2,label:'志愿者'}]" :key="i" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" size="small" @click="initMember">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="success" icon="el-icon-plus" size="small" @click="open('addMember')">新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="memberList" stripe style="width: 100%" height="500px">
            <el-table-column prop="name" label="姓名" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="sex" label="性别" show-overflow-tooltip width="60">
              <template slot-scope="scope">
                <el-tag type="primary" size="small" v-show="scope.row.sex===0">男</el-tag>
                <el-tag type="danger" size="small" v-show="scope.row.sex===1">女</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" show-overflow-tooltip width="60">
            </el-table-column>
            <el-table-column prop="job" label="职务" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="type" label="类型" show-overflow-tooltip width="80">
              <template slot-scope="scope">
                <el-tag type="info" size="small" v-show="scope.row.type===0">兼职</el-tag>
                <el-tag type="info" size="small" v-show="scope.row.type===1">专职</el-tag>
                <el-tag type="info" size="small" v-show="scope.row.type===2">志愿者</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="账号" show-overflow-tooltip width="100">
              <template slot-scope="scope">
                <el-tag type="success" size="small" v-show="scope.row.userId>0">已绑定</el-tag>
                <el-tag type="danger" size="small" v-show="!scope.row.userId">未绑定</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="修改" placement="top">
                  <el-button size="mini" @click="open('editMember',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <el-button type="danger" @click="deleteMember(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="绑定账号" placement="top">
                  <el-button type="primary" @click="open('bind',scope.row.id)" size="mini" icon="el-icon-link" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-pagination @current-change="handleCurrentChange2" :current-page.sync="memberPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="memberTotal">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 专职队人员表单 -->
    <el-dialog :title="optFlag3?'添加专职队人员':'修改专职队人员'" :visible.sync="memberInfoShow" width="40%" @close="clear" top="3%">
      <el-form :model="member" ref="member" :rules="rules" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="member.name" autocomplete="off" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="性别">
              <el-radio-group v-model="member.sex">
                <el-radio v-for="(item,i) in [{id:0,label:'男'},{id:1,label:'女'}]" :key="i" :label="item.id">
                  {{item.label}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="年龄" prop="age">
              <el-input-number v-model="member.age" size="normal" :min="1">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="手机号码" prop="phoneNumber">
              <el-input v-model="member.phoneNumber" placeholder="请输入手机号码" size="normal" clearable maxlength="11" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item label="职务" prop="job">
              <el-select v-model="member.job" value-key="" placeholder="请选择职务" clearable>
                <el-option v-for="(item,i) in jobList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item label="类型" prop="type">
              <el-select v-model="member.type" placeholder="请输入类型" clearable>
                <el-option v-for="(item,i) in [{id:0,label:'兼职'},{id:1,label:'专职'},{id:2,label:'志愿者'}]" :key="i" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="memberInfoShow = false">取消</el-button>
        <el-button type="primary" @click="addMember" v-if="optFlag3">添 加</el-button>
        <el-button type="primary" @click="updateMember" v-if="!optFlag3">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 账户列表 -->
    <el-dialog title="绑定用户" :visible.sync="accountShow" width="40%" @close="clear">
      <div class="container noangle" v-loading="loading2">
        <div class="header">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="专职队名称">
              <el-input v-model="searchAccount.username" placeholder="请输入" size="small" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="initAccount">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="accountList" style="width: 100%" height="500" highlight-current-row @current-change="changeCheck">
            <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="username" label="名称" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="accountShow = false">取 消</el-button>
        <el-button type="primary" @click="bindAccount">绑 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
export default {
  components: {
    BaiduMap,
    BmMarker
  },
  data () {
    return {
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      currentPage: 1,
      loading: false,
      loading1: false,
      loading2: false,
      isLoad: false,
      optFlag: false, // 专职队控制
      optFlag2: false, // 地图控制
      optFlag3: false, // 专职队人员控制
      areaCodes: [],
      formLabelWidth: '130px',
      formLabelWidth2: '120px',
      total: 0,
      brigadeList: [],
      accountList: [],
      areaList: [],
      fileList: [],
      brigadeId: 0, // 单位ID
      brigade: {
      }, // 建筑表单
      brigadeCopy: {},
      memberList: [],
      memberPage: 1,
      memberTotal: 0,
      member: {},
      memberId: 0,
      accountId: 0,
      typeList: [],
      jobList: [],
      fireUnitList: [],
      token: '',
      rules: {
        // typeId: [
        //   { required: true, message: '请选择消防专职队类型', trigger: 'change' }
        // ],
        // equipmentCode: [
        //   { required: true, message: '请输入消防专职队特征码', trigger: 'blur' }
        // ],
        // stationCode: [
        //   { pattern: /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9]{1,10}$)/, message: '请输入合法的基站特征码', trigger: 'blur' }
        // ],
        // contactPhone: [
        //   { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请输入正确的的手机号', trigger: 'blur' }
        // ]
      },
      searchForm: {},
      searchMember: {},
      searchAccount: {},
      dialogImageUrl: '',
      dialogVisible: false,
      brigadeShow: false,
      mapShow: false,
      memberShow: false,
      memberInfoShow: false,
      accountShow: false
    }
  },
  created () {
    this.initTable()
    this.getType()
    this.getAreaList()
    this.getJob()
    this.brigadeCopy = JSON.stringify(this.brigade)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 人员分页
    async handleCurrentChange2 (val) {
      this.memberPage = val
      this.initMember()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.searchForm.name) {
        data.name = this.searchForm.name
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/fire-brigade/list', { params: data })
      if (result.code === 200) {
        this.loading = false
        this.brigadeList = result.data.result
        this.total = result.data.total
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteBrigade (id) {
      const confirm = await this.$confirm('此操作将永久删除该消防专职队, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fire-brigade/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateBrigade () {
      this.$refs.brigade.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/fire-brigade/edit/${this.brigadeId}`, this.brigade)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.brigadeShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 新增专职队
    async addBrigade () {
      this.$refs.brigade.validate(async valid => {
        if (valid) {
          if (this.brigade.areaCodes.length > 0) {
            this.brigade.areaCode = this.brigade.areaCodes[this.brigade.areaCodes.length - 1]
          } else {
            this.$message.error('请选择该单位所在行政区域')
            return
          }
          const { data: result } = await this.$axios.post('/fire-brigade/add', this.brigade)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.brigadeShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 人员列表
    async initMember () {
      const data = {}
      if (this.searchMember.name) {
        data.name = this.searchMember.name
      }
      data.fireBrigadeId = this.brigadeId
      data.pageNo = this.memberPage
      data.pageSize = 10
      this.loading1 = true
      const { data: result } = await this.$axios.get('/fire-brigade-person/list', { params: data })
      if (result.code === 200) {
        this.memberList = result.data.result
        this.memberTotal = result.data.total
        this.loading1 = false
      }
    },
    changeCheck (val) {
      this.accountId = val.id
      console.log(val)
    },
    // 删除某条数据
    async deleteMember (id) {
      const confirm = await this.$confirm('此操作将永久删除该消防专职队, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fire-brigade-person/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.memberTotal - 1) / 10) // 总页数
          this.memberPage = this.memberPage > totalPage ? totalPage : this.memberPage
          this.memberPage = this.memberPage < 1 ? 1 : this.memberPage
          this.initMember()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateMember () {
      this.$refs.member.validate(async valid => {
        if (valid) {
          this.member.fireBrigadeId = this.brigadeId
          const { data: result } = await this.$axios.put(`/fire-brigade-person/edit/${this.memberId}`, this.member)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.memberInfoShow = false
            this.initMember()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 新增专职队
    async addMember () {
      this.$refs.member.validate(async valid => {
        if (valid) {
          this.member.fireBrigadeId = this.brigadeId
          const { data: result } = await this.$axios.post('/fire-brigade-person/add', this.member)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.memberTotal + 1) / 10) // 总页数
            this.memberPage = this.memberPage > totalPage ? totalPage : this.memberPage
            this.memberPage = this.memberPage < 1 ? 1 : this.memberPage
            this.memberInfoShow = false
            this.initMember()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 账户列表
    async initAccount () {
      this.loading2 = true
      const { data: result } = await this.$axios.get('/user/list', { params: { username: this.searchAccount.username } })
      if (result.code === 200) {
        this.accountList = result.data
        this.loading2 = false
      }
    },
    // 绑定账号
    async bindAccount () {
      const data = {
        id: this.memberId,
        userId: this.accountId
      }
      const { data: result } = await this.$axios.post('/fire-brigade-person/bindAccount', data)
      if (result.code === 200) {
        this.$message.success('绑定成功')
        this.accountShow = false
        this.initMember()
      } else {
        this.$message.error(result.msg)
      }
    },
    // 获取位置
    async getPosition () {
      console.log(this.location)
      this.brigade.lng = this.location.lng
      this.brigade.lat = this.location.lat
      this.mapShow = false
    },
    // 设置位置
    async setPosition (e) {
      this.location.lng = e.point.lng
      this.location.lat = e.point.lat
    },
    // 地图初始化
    handler ({ BMap, map }) {
      const that = this
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition(function (r) {
        that.location.lng = r.point.lng
        that.location.lat = r.point.lat
      })
    },
    async open (flag, row) {
      switch (flag) {
        case 'add':
          this.brigadeShow = true
          // this.$nextTick(() => {
          //   this.$refs.brigade.clearValidate()
          // })
          this.optFlag = true
          break
        case 'edit':
          this.brigadeShow = true
          this.optFlag = false
          // this.$nextTick(() => {
          //   this.$refs.brigade.clearValidate()
          // })
          this.brigade = JSON.parse(JSON.stringify(row))
          this.brigadeId = row.id
          break
        case 'map':
          this.mapShow = true
          this.optFlag2 = true
          break
        case 'location':
          this.mapShow = true
          this.optFlag2 = false
          this.location = row
          break
        case 'member':
          this.brigadeId = row.id
          this.initMember()
          this.memberShow = true
          break
        case 'addMember':
          this.memberInfoShow = true
          this.optFlag3 = true
          break
        case 'editMember':
          this.memberInfoShow = true
          this.optFlag3 = false
          this.member = JSON.parse(JSON.stringify(row))
          this.memberId = row.id
          break
        case 'bind':
          this.accountShow = true
          this.memberId = row
          this.initAccount()
      }
    },
    // 查找消防站类型
    async getType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'fireStationType' } })
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    // 查找职务
    async getJob () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'brigadeJob' } })
      if (result.code === 200) {
        this.jobList = result.data
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    changeKey (array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    },
    // 查看图片
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getImage (res, file, fileList) {
      if (res.code === 200) {
        this.brigade.imageUrl = res.data
      }
    },
    error (err, file, fileList) {
      this.$message.error(err)
    },
    // 关闭弹窗清空表单
    clear () {
      this.brigade = JSON.parse(this.brigadeCopy)
      // this.$refs.brigade.resetFileds()
      this.brigade = {}
      this.member = {}
      this.searchMember = {}
      this.searchAccount = {}
      // this.$refs.brigade.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.map {
  width: 100%;
  height: 500px !important;
  .bmView {
    height: 100% !important;
    > div:first-child {
      height: 500px !important;
    }
  }
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
::v-deep  :focus {
  outline: 0;
}
::v-deep .el-drawer__body {
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  overflow-y: scroll;
  p {
    margin-top: 10px;
  }
  .el-timeline-item__timestamp {
    color: #fff;
  }
  .el-timeline-item__content {
    .el-card {
      background-color: rgba(7, 104, 159, 0.1);
      border: 1px solid rgb(7, 104, 159);
      box-shadow: 0 0 10px #42affe inset;
      color: #fff;
      h3 {
        margin-bottom: 10px;
        i {
          margin-right: 3px;
        }
      }
      h3:last-chlid {
        margin-bottom: 0;
      }
    }
  }
  .el-timeline-item__tail {
    border: 1px solid rgb(66, 175, 254);
  }
  .el-timeline-item__node {
    background-color: rgb(66, 175, 254);
    box-shadow: 0 0 10px #42affe;
  }
}
</style>
